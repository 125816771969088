import { classesToClassList, typography } from "@components/solid/classLists";
import {
  getCurrentTimeOrCurrentTimeLive,
  loadOrPlayPause,
} from "@components/solid/Player/AudioPlayer";
import { isMediaId } from "@components/solid/Player/lib/toSrc";
import { playingMediaId } from "@components/solid/Player/states/playingMediaIdState";
import { nrk404 } from "@nrk/core-icons";
import { type Component } from "solid-js";
import styles from "./ErrorMessage.module.css";
import miniPlayerStyles from "./Miniplayer.module.css";

export const PlayerErrorMessage: Component<{}> = () => {
  return (
    <div class={miniPlayerStyles.MiniPlayerWrapper}>
      <div
        classList={classesToClassList(
          miniPlayerStyles.MiniPlayer,
          styles.ErrorMessage,
        )}
      >
        <button
          classList={{
            ...classesToClassList(styles.button),
          }}
          onClick={async () => {
            const mediaId = playingMediaId();
            if (isMediaId(mediaId)) {
              await loadOrPlayPause(mediaId, getCurrentTimeOrCurrentTimeLive());
            }
          }}
        >
          <span class={styles.icon} innerHTML={nrk404} />
          <div class={styles.textWrapper}>
            <span classList={typography({ name: "SubheadBold" })}>
              Noe gikk galt
            </span>
            <span classList={typography({ name: "Footnote" })}>
              Klikk her for å prøve på nytt
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};
