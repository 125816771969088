import { classesToClassList } from "@components/solid/classLists";
import { loadOrPlay } from "@components/solid/Player/AudioPlayer";
import IndexPointsList from "@components/solid/Player/controls/IndexPointsAndQueue/IndexPointsList";
import { MEDIA_TYPES } from "@components/solid/Player/lib/toSrc";
import {
  currentlyPlayingIndexPoint,
  hasIndexPoints,
  indexPoints,
  liveIndexPoints,
} from "@components/solid/Player/states/indexPointsProvider";
import { playingMediaId } from "@components/solid/Player/states/playingMediaIdState";
import { createVisibilityObserver } from "@solid-primitives/intersection-observer";
import {
  createEffect,
  For,
  Match,
  Show,
  Switch,
  type Component,
} from "solid-js";
import style from "./IndexPoints.module.css";

const IndexPoints: Component<{}> = () => {
  let componentRef: HTMLElement | undefined;
  let scrollElement: HTMLElement | undefined;
  const isComponentVisible = createVisibilityObserver({ threshold: 0 })(
    () => componentRef,
  );

  createEffect(() => {
    const currentlyPlayingIndexPointId = currentlyPlayingIndexPoint()?.id;
    if (!currentlyPlayingIndexPointId) return;
    const listItem = scrollElement?.querySelector(
      `#player--menu-index-point-list-item-${currentlyPlayingIndexPointId}`,
    ) as HTMLElement;

    if (
      listItem &&
      scrollElement &&
      isComponentVisible() &&
      !isItemVisibleInScrollParent(scrollElement, listItem)
    ) {
      scrollElement.scrollTo({
        top: calculateScrollPosition(scrollElement, listItem),
      });
    }
  });

  return (
    <div class={style.IndexPoints} ref={(el) => (componentRef = el)}>
      <Show
        when={playingMediaId() && hasIndexPoints()}
        fallback={<MissingIndexPointsMessage />}
      >
        <div
          ref={(el) => (scrollElement = el)}
          class={style.IndexPoints__listWrapper}
        >
          <Switch>
            <Match when={playingMediaId()?.mediaType === MEDIA_TYPES.CHANNEL}>
              <For each={liveIndexPoints()}>
                {(liveIndexPoint) => {
                  const liveElements = liveIndexPoint.liveElements;
                  return (
                    <>
                      <button
                        id={`player--menu-index-point-list-item-${liveIndexPoint.id}`}
                        classList={classesToClassList(
                          "nrk-typography-subhead--bold",
                          style.IndexPoints__list__heading,
                        )}
                        onClick={() => {
                          const mediaId = playingMediaId();
                          if (mediaId) {
                            loadOrPlay(mediaId, liveIndexPoint.start);
                          }
                        }}
                      >
                        {liveIndexPoint.programTitle}
                      </button>
                      {liveElements && liveElements?.length > 0 && (
                        <IndexPointsList
                          indexPoints={liveElements}
                          mediaId={playingMediaId()}
                        />
                      )}
                    </>
                  );
                }}
              </For>
            </Match>
            <Match when={playingMediaId()?.mediaType !== MEDIA_TYPES.CHANNEL}>
              <IndexPointsList
                indexPoints={indexPoints()}
                mediaId={playingMediaId()}
              />
            </Match>
          </Switch>
        </div>
      </Show>
    </div>
  );
};

export default IndexPoints;

function calculateScrollPosition(
  scrollParent: HTMLElement,
  child: HTMLElement,
) {
  const parentRect = scrollParent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();
  const offsetTop = childRect.top - parentRect.top + scrollParent.scrollTop;
  const scrollPosition =
    offsetTop - parentRect.height / 2 + childRect.height / 2;

  return scrollPosition;
}

function isItemVisibleInScrollParent(
  scrollParent?: HTMLElement,
  child?: HTMLElement,
) {
  if (!scrollParent || !child) {
    !scrollParent && console.error("scrollParent is not defined");
    !child && console.error("child is not defined");

    return false;
  }

  const childRect = child.getBoundingClientRect();
  const parentRect = scrollParent.getBoundingClientRect();

  const isVisible =
    childRect.top >= parentRect.top && childRect.bottom <= parentRect.bottom;

  return isVisible;
}

function MissingIndexPointsMessage() {
  return <div>Programmet er dessverre ikke inndelt i tidspunkter</div>;
}
