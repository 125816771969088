import { button, classesToClassList } from "@components/solid/classLists";
import IndexPoints from "@components/solid/Player/controls/IndexPointsAndQueue/IndexPoints";
import PlayerDialog from "@components/solid/Player/controls/PlayerDialog";
import { nrkMediaPlaylist } from "@nrk/core-icons";
import "@u-elements/u-tabs";
import { type Component } from "solid-js";
import style from "./IndexPointsAndQueue.module.css";

const IndexPointsAndQueue: Component<{}> = (props) => {
  return (
    <PlayerDialog buttonContent={nrkMediaPlaylist}>
      <u-tabs class={style.tabs}>
        <u-tablist class={style.tablist}>
          <u-tab
            classList={{
              ...button({ type: "primary", size: "l" }),
              ...classesToClassList(style.tab),
            }}
          >
            Tidspunkter
          </u-tab>
          <u-tab
            classList={{
              ...button({ type: "primary", size: "l" }),
              ...classesToClassList(style.tab),
            }}
          >
            Kø
          </u-tab>
        </u-tablist>
        <u-tabpanel class={style.tabPanel}>
          <IndexPoints />
        </u-tabpanel>
        <u-tabpanel class={style.tabPanel}>Kø (TODO)</u-tabpanel>
      </u-tabs>
    </PlayerDialog>
  );
};

export default IndexPointsAndQueue;
