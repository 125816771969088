import {
  hasPlaybackSession,
  loadAudioPlayer,
} from "@components/solid/Player/AudioPlayer";
import { CurrentlyPlayingProvider } from "@components/solid/Player/CurrentlyPlayingProvider";
import {
  persistedLastPlayed,
  persistedMuted,
  persistedVolume,
} from "@components/solid/Player/lib/persistedPlayerStates";
import { MiniPlayer } from "@components/solid/Player/MiniPlayer";
import { PlayerErrorMessage } from "@components/solid/Player/MiniPlayer/ErrorMessage";
import { ProgressSaveProvider } from "@components/solid/Player/ProgressSaveProvider";
import { playbackState } from "@components/solid/Player/states/playbackState";
import { Match, onMount, Switch, type ParentComponent } from "solid-js";

export const Player: ParentComponent = () => {
  onMount(() => {
    const persistedMediaIds = persistedLastPlayed();

    if (persistedMediaIds) {
      loadAudioPlayer(persistedMediaIds).then((playbackSession) => {
        persistedMuted() && playbackSession.mute();
        playbackSession.setVolume(persistedVolume());
      });
    }
  });

  return (
    <>
      <ProgressSaveProvider />
      <CurrentlyPlayingProvider />
      <Switch>
        <Match when={playbackState.broken}>
          <PlayerErrorMessage />
        </Match>
        <Match when={hasPlaybackSession()}>
          <MiniPlayer />
        </Match>
      </Switch>
    </>
  );
};
