import { loadOrPlay } from "@components/solid/Player/AudioPlayer";
import {
  type IndexPoint,
  type LiveElement,
} from "@components/solid/Player/lib/indexPointsHelpers";
import type { MediaIds } from "@components/solid/Player/lib/toSrc";
import { currentlyPlayingIndexPoint } from "@components/solid/Player/states/indexPointsProvider";
import { classesToClassList } from "@components/solid/classLists";
import Soundwave from "@components/solid/common/Soundwave";
import { nrkBulletSquare, nrkNote1 } from "@nrk/core-icons";
import { For, Match, Switch, type Component } from "solid-js";
import style from "./IndexPoints.module.css";

const IndexPointsList: Component<{
  indexPoints: IndexPoint[] | LiveElement[];
  mediaId?: MediaIds;
}> = (props) => {
  return (
    <ul class={style.IndexPoints__list}>
      <For each={props.indexPoints}>
        {(indexPoint) => {
          const isPlaying = () =>
            currentlyPlayingIndexPoint() &&
            indexPoint.id &&
            currentlyPlayingIndexPoint()?.id === indexPoint.id;
          return (
            <li
              id={`player--menu-index-point-list-item-${indexPoint.id}`}
              class={style.IndexPoints__list__item}
            >
              <button
                classList={{
                  ...classesToClassList(style.IndexPoints__button),
                  "nrk-typography-body": indexPoint.type === "music",
                  "nrk-typography-headline": indexPoint.type == "non-music",
                  [style.playing]: isPlaying(),
                }}
                disabled={indexPoint.type === "music"}
                onClick={() => {
                  if (props.mediaId) {
                    loadOrPlay(props.mediaId, indexPoint.start);
                  }
                }}
              >
                <Switch>
                  <Match when={isPlaying()}>
                    <span class={style.IndexPoints__button__icon}>
                      <Soundwave />
                    </span>
                  </Match>
                  <Match when={indexPoint.type === "music"}>
                    <span
                      class={style.IndexPoints__button__icon}
                      innerHTML={nrkNote1}
                    />
                  </Match>
                  <Match when={indexPoint.type === "non-music"}>
                    <span
                      class={style.IndexPoints__button__icon}
                      innerHTML={nrkBulletSquare}
                    />
                  </Match>
                </Switch>
                <span class={style.IndexPoints__textWrapper}>
                  <time
                    class={style.IndexPoints__timestamp}
                    dateTime={indexPoint.formattedStart}
                  >
                    {indexPoint.formattedStart}
                  </time>
                  <span class={style.IndexPoints__title}>
                    {indexPoint.type === "music" && indexPoint.artist
                      ? `${indexPoint.artist} - ${indexPoint.title}`
                      : indexPoint.title}
                  </span>
                </span>
              </button>
            </li>
          );
        }}
      </For>
    </ul>
  );
};

export default IndexPointsList;
